<template>
  <div>
    <div class="static-container">
      <div class="static-title">
        {{ $trans('上殊房网页用户注册协议', '上殊房网页用户注册协议') }}
      </div>
      <div class="static-paragraph" v-for="item in regAgreement" :key="item.id">
        <div class="static-paragraph-title">{{ item.title }}</div>
        <div class="static-p" v-for="(i, index) in item.content" :key="index">
          {{ i.text }}
          <p v-for="(it, ind) in i.content" :key="ind">{{ it }}</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/layout/Footer.vue'
import { regAgreement } from '@/util/resource/static.jsx'
export default {
  components: {
    Footer,
  },
  computed: {
    regAgreement() {
      return regAgreement()
    },
  },
}
</script>
<style scoped>
.static-container {
  width: 800px;
  padding: 100px 0;
  margin: 0 auto;
}
.static-title {
  font-family: 'myCoolFontMedium';
  font-size: 20px;
  margin-bottom: 60px;
}
.static-paragraph {
  margin-bottom: 25px;
}
.static-paragraph-title {
  font-family: 'myCoolFontMedium';
  font-size: 14px;
  line-height: 34px;
}
.static-p {
  font-size: 14px;
  line-height: 34px;
}
</style>
